import React from "react";
import { ArrowLeft, User } from "tabler-icons-react";
import Credit from "components/Credit";
import Timeline from "components/Timeline";
import ProgressIndicator from "../ProgressIndicator";

const GameHeader = ({ handleGoBack, eleve, current, data, randomeImage, studentDemo }) => (
    <div className="flex flex-col">
        <Header handleGoBack={handleGoBack} eleve={eleve} studentDemo={studentDemo} data={data} />
        <Timeline data={data} />
        <ProgressIndicator current={current} data={data?.data} randomeImage={randomeImage} />
    </div>
);

const Header = ({ handleGoBack, eleve, studentDemo, data }) => (
    <div className="flex justify-between items-center py-2 h-[80px] sticky top-0 bg-white z-30">
        <div className="flex gap-2">
            <div className="cursor-pointer" onClick={handleGoBack}>
                <ArrowLeft />
            </div>
            <div className="h-8 w-8 bg-primary rounded-full flex items-center justify-center">
                <User color="#fff" size={18} />
            </div>
            <div className="text-sm">
                <p className="-mb-1 font-bold">{studentDemo?.fullName}</p>
                <p className="text-xs">{studentDemo?.classe}</p>
            </div>
        </div>
        <div className="flex flex-col items-end">
            <Credit />
            <p className="text-center px-3 py-1 rounded-2xl text-xs bg-gray-200">
                Diagnostic {data?.type ?? "Mathematique"}
            </p>
        </div>
    </div>
);

export default GameHeader;
