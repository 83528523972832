import React, { useEffect } from "react";
import { InputAnswerAreaForLetters } from "./StyledComponent";

const ResolutionProbleme = ({ item, consigneValues, setConsigneValues, handleAddWord }) => {
    // Initialize consigneValues based on item.correction_dp
    useEffect(() => {
        if (item?.correction_dp) {
            const initializedValues = item.correction_dp.map((step, index) => ({
                value: step,
                userValue: consigneValues[index]?.userValue || "", // Preserve existing userValue if present
            }));
            setConsigneValues(initializedValues);
        }
    }, [item, setConsigneValues]);

    return (
        <div>
            <p>{item?.consigne[0]}</p>
            <div className="flex-row mt-5">
                <p className="py-4 font-bold">Saisir les opérations qui permettent de résoudre le problème :</p>
                {item.correction_dp.map((step, index) => (
                    <InputAnswerAreaForLetters
                        key={index}
                        value={consigneValues[index]?.userValue || ""}
                        onChange={(e) => {
                            const updatedValues = [...consigneValues];
                            updatedValues[index] = {
                                ...updatedValues[index],
                                userValue: e.target.value,
                            };
                            setConsigneValues(updatedValues);
                        }}
                        onBlur={(e) => handleAddWord(e.target.value)}
                        placeholder="10 + 20 = 30"
                        className="mr-4"
                    />
                ))}
            </div>
        </div>
    );
};

export default ResolutionProbleme;
