import React from "react";
import TextToSpeech from "../TextSpeach";

const WinWil = ({ consigne, textRecorded, onTextRecorded }) => {
    return (
        <div className="w-full flex flex-col gap-10">
            <TextToSpeech textRecorded={textRecorded} onChange={onTextRecorded} text={consigne?.join("; ")} />
        </div>
    );
};

export default WinWil;
