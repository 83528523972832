import React from "react";
import { InputAnswerAreaForLetters } from "./StyledComponent";

const WordToNumbers = ({ item, consigneValues, setConsigneValues }) => {
    return (
        <div className="flex flex-row flex-wrap gap-3 justify-center">
            {item?.consigne.map((consigneItem, index) => (
                <div key={index} className="flex flex-col items-center gap-2">
                    <p className="font-bold text-lg">
                        {JSON.parse(consigneItem)?.value || "Valeur manquante"}
                    </p>
                    <InputAnswerAreaForLetters
                        value={consigneValues[index]?.userValue || ""}
                        onChange={(e) => {
                            const updatedValues = [...consigneValues];
                            updatedValues[index].userValue = e.target.value;
                            setConsigneValues(updatedValues);
                        }}
                        placeholder="Votre réponse"
                    />
                </div>
            ))}
        </div>
    );
};

export default WordToNumbers;
