import React, { useEffect, useState } from "react";
import { AnswerArea, Card, InputAnswerArea } from "./StyledComponent";
import styled from 'styled-components';

// Define a styled component with conditional styles
const StyledInputAnswerArea = styled(InputAnswerArea)`
    border: 2px solid ${({ isActive, isNotEmpty }) => (isActive ? '#003B7F' : isNotEmpty ? 'green' : '#ccc')};
    // Add any other styles you want here
`;

const Od = ({ item, setUserAnswer }) => {
    const [consigneValues, setConsigneValues] = useState(() => {
        return item.consigne.map((consigne) => "")
    });

    useEffect(() => {
        setUserAnswer(consigneValues);
    }, [])

    if (!item || !item.consigne || !Array.isArray(item.consigne)) {
        return <div>Invalid data for od type</div>;
    }

    const handleCardClick = (parsedItem) => {
        const updatedValues = [...consigneValues];
        const itemIndex = updatedValues.findIndex(value => value === parsedItem);

        if (itemIndex !== -1) {
            // Remove the number if it's already selected
            updatedValues[itemIndex] = ""; // Clear the selected number
        } else {
            // Add the new number to the first empty input
            const firstEmptyIndex = updatedValues.findIndex(value => value === "");
            if (firstEmptyIndex !== -1) {
                updatedValues[firstEmptyIndex] = parsedItem;
            }
        }

        setConsigneValues(updatedValues);
        setUserAnswer(updatedValues);
    };

    return (
        <div>
            <p className="py-4">Cliquez sur les cartes pour sélectionner les nombres :</p>
            <div className="flex gap-4 flex-wrap items-center justify-center">
                {consigneValues.map((consigne, index) => {
                    const isActive = consigneValues[index] !== ""; // Check if the input is not empty
                    return (
                        <StyledInputAnswerArea
                            key={index}
                            value={consigneValues[index] || ""}
                            className="mb-3 p-2"
                            readOnly
                            placeholder=""
                            isActive={consigneValues[index] !== ""} // Pass active state
                            isNotEmpty={consigne !== ""} // Pass not empty state
                            onClick={() => {
                                // Deselect the number when the input is clicked
                                if (consigneValues[index]) {
                                    const updatedValues = [...consigneValues];
                                    updatedValues[index] = ""; // Clear the selected number
                                    setConsigneValues(updatedValues);
                                    setUserAnswer(updatedValues);
                                }
                            }}
                        />
                    );
                })}
            </div>
            <div className="flex gap-4 flex-wrap items-center justify-center mt-4">
                {item?.consigne.map((consigneItem, index) => {
                    const parsedItem = JSON.parse(consigneItem);
                    return (
                        <Card
                            key={index}
                            style={{
                                borderColor: consigneValues.includes(parsedItem)
                                    ? "#003B7F"
                                    : "#ccc",
                            }}
                            onClick={() => handleCardClick(parsedItem)}
                        >
                            {parsedItem}
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};

export default Od;
