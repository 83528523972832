import React from "react";
import { InputAnswerArea } from "./StyledComponent";

const TrouverLInconnu = ({ item, consigneValues, setConsigneValues }) => {
    return (
        <div>
            <p className="py-4">Résolvez pour trouver l'inconnu :</p>
            <div className="flex gap-4 flex-wrap items-center justify-center">
                {item?.consigne.map((consigneItem, index) => (
                    <div key={index} className="flex flex-col items-center gap-2">
                        <p className="font-bold text-lg">{consigneItem?.value || "?"}</p>
                        <InputAnswerArea
                            value={consigneValues[index]?.userValue || ""}
                            onChange={(e) => {
                                const updatedValues = [...consigneValues];
                                updatedValues[index] = { ...updatedValues[index], userValue: e.target.value };
                                setConsigneValues(updatedValues);
                            }}
                            placeholder="?"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TrouverLInconnu;
